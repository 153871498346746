import React from "react"

const ParallaxSection = () => {
  return (
    <>
      <div className="parallax-bg bg-hero fadein"></div>
    </>
  )
}

export default ParallaxSection
