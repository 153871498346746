import React from "react"

const Footer = () => {
  return (
    <footer className="footer font-headline">
      <div className="copyright container-wide">
        &copy;{new Date().getFullYear()} Ema Ryan Yamazaki
      </div>
    </footer>
  )
}

export default Footer
