import React, { useEffect } from "react"
import PropTypes from "prop-types"

import SEO from "./SEO"
import "../assets/scss/styles.scss"
import Header from "./Header"
import ParallaxSection from "./ParallaxSection"
import Footer from "./Footer"

const Layout = ({ lang, children, pageSlug, location }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        if (pageSlug === "index-en" || pageSlug === "index-ja") {
          // Parallax function
          const parallax = document.querySelector(".parallax-bg")
          const pageYOffset = window.pageYOffset
          const ParallaxPoint1 = document.querySelector(".works")
          const ParallaxPoint2 = document.querySelector(".contact")
          const footer = document.querySelector(".footer")

          const ParallaxPoint1toTop =
            pageYOffset + ParallaxPoint1.getBoundingClientRect().top
          const ParallaxPoint2toTop =
            pageYOffset + ParallaxPoint2.getBoundingClientRect().top

          //Replace class depending on scroll position
          if (pageYOffset < ParallaxPoint1toTop) {
            parallax.classList.remove("bg-bicycle")
            parallax.classList.remove("bg-monkey")
            parallax.classList.add("bg-hero")
          } else if (
            pageYOffset > ParallaxPoint1toTop &&
            pageYOffset < ParallaxPoint2toTop
          ) {
            parallax.classList.remove("bg-hero")
            parallax.classList.remove("bg-monkey")
            parallax.classList.add("bg-bicycle")
          } else {
            parallax.classList.remove("bg-hero")
            parallax.classList.remove("bg-bicycle")
            parallax.classList.add("bg-monkey")
          }

          const bodyHeight = document.body.clientHeight
          if (pageYOffset > bodyHeight * 0.9) {
            footer.classList.add("fixed")
          } else {
            footer.classList.remove("fixed")
          }
        }
      },
      []
    )

    // Intersection Observer function
    const intersection = document.querySelectorAll(".intersection")
    const options = {
      root: null,
      rootMargin: "-50%",
      threshold: 0,
    }
    const observer = new IntersectionObserver(inView, options)
    intersection.forEach(intersection => {
      observer.observe(intersection)
    })

    function inView(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("inview") // Add inview class to current section
        } else {
          entry.target.classList.remove("inview") // Remove inview class to current section
        }
      })
    }
  }, [pageSlug])

  return (
    <>
      <SEO lang={lang} pageSlug={pageSlug} location={location} />
      <Header lang={lang} pageSlug={pageSlug} />
      <ParallaxSection />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
