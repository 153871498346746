import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Header = ({ lang, pageSlug }) => {
  const data = useStaticQuery(graphql`
    query {
      imgLogoJa: file(relativePath: { eq: "logo-ja.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const isLangEn = lang === "en"
  const isLangJa = lang === "ja"

  const [menu, setMenu] = useState(false)
  const imgLogoJa = data.imgLogoJa.childImageSharp.fluid

  const [isWorks, setIsWorks] = useState(false)
  const [isAbout, setIsAbout] = useState(false)
  const [isContact, setIsContact] = useState(false)
  const [isPress, setIsPress] = useState(false)

  useEffect(() => {
    // Prevent body scrolling in modal background
    if (menu === false) {
      document.body.classList.remove("lock-sp")
    } else {
      document.body.classList.add("lock-sp")
    }

    window.addEventListener("scroll", function () {
      if (pageSlug === "index-en" || pageSlug === "index-ja") {
        // Handle scroll event
        const header = document.querySelector(".header")
        const yOffset = window.pageYOffset
        if (yOffset > 400) {
          header.classList.add("scrolled")
        } else {
          header.classList.remove("scrolled")
        }

        // Handle inview status
        const WorksClass = document.querySelector(".works").classList
        const AboutClass = document.querySelector(".about").classList
        const ContactClass = document.querySelector(".contact").classList
        const PressClass = document.querySelector(".press").classList
        if (WorksClass.contains("inview")) {
          setIsWorks("current")
        } else {
          setIsWorks("")
        }
        if (AboutClass.contains("inview")) {
          setIsAbout("current")
        } else {
          setIsAbout("")
        }
        if (ContactClass.contains("inview")) {
          setIsContact("current")
        } else {
          setIsContact("")
        }
        if (PressClass.contains("inview")) {
          setIsPress("current")
        } else {
          setIsPress("")
        }
      }
    })
  })

  let langDir

  if (isLangJa) {
    langDir = "/jp/"
  } else {
    langDir = "/en/"
  }

  function mobileMenuClose() {
    setMenu(false)
  }

  return (
    <>
      <header className="header">
        <nav className={`nav-global container-wide ${menu ? "is-open" : ""}`}>
          <button
            className={`nav-button fadein delay4 ${menu ? "is-active" : ""}`}
            type="button"
            aria-label="Menu"
            onClick={() => setMenu(!menu)}
          >
            <span className="ico-humbarger"></span>
          </button>

          <h1 className="nav-logo fadein delay0 font-headline only-for-medium">
            <Link to={langDir}>
              {isLangEn ? (
                <>
                  <div className="logo__en">Ema Ryan Yamazaki</div>
                  <div className="logo__ja">
                    <Img fluid={imgLogoJa} alt="山崎エマ" />
                  </div>
                </>
              ) : (
                <>
                  <div className="logo__ja">
                    <Img fluid={imgLogoJa} alt="山崎エマ" />
                  </div>
                  <div className="logo__en">Ema Ryan Yamazaki</div>
                </>
              )}
            </Link>
          </h1>

          <div className={`nav-menu ${menu ? "is-active" : ""}`}>
            <ul className="nav-menu__lists no-bullet font-headline fadein delay1 ">
              <li className={`nav-menu__list ${isWorks}`}>
                <Link
                  to={langDir + "#works"}
                  className={`nav-menu__link nohover`}
                  onClick={mobileMenuClose}
                >
                  Works
                </Link>
              </li>
              <li className={`nav-menu__list ${isAbout}`}>
                <Link
                  to={langDir + "#about"}
                  className="nav-menu__link nohover"
                  onClick={mobileMenuClose}
                >
                  About
                </Link>
              </li>
              <li className={`nav-menu__list ${isContact}`}>
                <Link
                  to={langDir + "#contact"}
                  className="nav-menu__link nohover"
                  onClick={mobileMenuClose}
                >
                  Contact
                </Link>
              </li>
              <li className={`nav-menu__list ${isPress}`}>
                <Link
                  to={langDir + "#press"}
                  className="nav-menu__link nohover"
                  onClick={mobileMenuClose}
                >
                  Press
                </Link>
              </li>
            </ul>
          </div>

          <div className={`nav-lang fadein delay3 ${lang}`}>
            <div
              className={`nav-lang__list nav-lang__list--en${
                isLangEn ? " current" : ""
              }`}
            >
              <a href="/en/" className="nav-lang__link link-ul font-headline">
                En
              </a>
            </div>
            <div
              className={`nav-lang__list nav-lang__list--ja${
                isLangJa ? " current" : ""
              }`}
            >
              <a href="/jp/" className="nav-lang__link link-ul font-headline">
                日本語
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
